<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Roles">
                <delete-items-button v-if="$hasPermission($permissions.ManageRoles)" :selection="selection" @deleted="loadData" @reset=" selection.selected_item_ids = []"/>

                <b-button variant="primary" @click="addRoleModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table ref="basicTable" :columns="columns" :data="roles" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                    <div v-if="props.column.displayType === 2 && $hasPermission($permissions.ManageRoles)" class="d-flex justify-content-center">
                        <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
                    </div>
                    <div v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="openEdit(rolesRaw[props.row.index])" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button variant="danger" @click="removeRole(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Add role" v-model="addRoleModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="addObject.name"/>
                </b-form-group>

                <b-form-group>
                    <label>Permissions</label>
                    <b-form-group>
                        <PermissionsList v-model="addObject.permissions"/>
                    </b-form-group>
                </b-form-group>

                <b-form-group>
                    <label>Areas</label>
                    <v-select
                        v-model="addObject.areas"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="areas"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Territories</label>
                    <v-select
                        v-model="addObject.territories"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="territories"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Districts</label>
                    <v-select
                        v-model="addObject.districts"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="districts"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addRoleModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addRole">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>

        <b-modal title="Edit role" v-model="editRoleModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="editObject.name"/>
                </b-form-group>

                <b-form-group>
                    <label>Permissions</label>
                    <b-form-group>
                        <PermissionsList v-model="editObject.permissions"/>
                    </b-form-group>
                </b-form-group>

                <b-form-group>
                    <label>Areas</label>
                    <v-select
                        v-model="editObject.areas"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="areas"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Territories</label>
                    <v-select
                        v-model="editObject.territories"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="territories"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Districts</label>
                    <v-select
                        v-model="editObject.districts"
                        :reduce="type => type.id"
                        label="name"
                        multiple
                        :options="districts"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editRoleModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="saveRole">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BFormInput, BModal, BOverlay, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'
    import PermissionsList from '@/views/components/PermissionsList'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'

    export default {
        components: {
            DeleteItemsButton,
            PermissionsList,
            BCard,
            vSelect,
            BOverlay,
            BasicTable,
            BButton,
            BFormGroup,
            BFormInput,
            BModal,
            BFormCheckbox

        },
        data() {
            return {
                dataLoaded: false,
                rolesRaw: [],

                areas: [],
                territories: [],
                districts: [],
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },

                columns: [
                    {
                        label: 'Select',
                        displayType: 2,
                        field: 'select',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Permissions',
                        displayType: 0,
                        field: 'permission_num',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Areas',
                        displayType: 0,
                        field: 'area_num',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Territories',
                        displayType: 0,
                        field: 'territory_num',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Districts',
                        displayType: 0,
                        field: 'district_num',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addRoleModalActive: false,
                addObject: {
                    name: '',
                    permissions: [],
                    areas: [],
                    territories: [],
                    districts: []
                },

                editRoleModalActive: false,
                editObject: {
                    name: '',
                    permissions: [],
                    areas: [],
                    territories: [],
                    districts: []
                }
            }
        },
        methods: {
            openEdit(role) {
                this.editObject = JSON.parse(JSON.stringify(role))
                this.$set(this.editObject, 'permissions', role.permissions)
                this.editRoleModalActive = true
            },
            reloadRoles() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/role')
                loadPromise.then(function(response) {
                    thisIns.rolesRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            loadData() {
                this.$refs.basicTable.$refs.table.reset()
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/role')
                loadPromise.then(function(response) {
                    thisIns.rolesRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const areasLoadPromise = this.$http.get('/api/management/v1/area')
                areasLoadPromise.then(function(response) {
                    thisIns.areas = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const territoriesLoadPromise = this.$http.get('/api/management/v1/territory')
                territoriesLoadPromise.then(function(response) {
                    thisIns.territories = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const districtsLoadPromise = this.$http.get('/api/management/v1/district')
                districtsLoadPromise.then(function(response) {
                    thisIns.districts = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, areasLoadPromise, territoriesLoadPromise, districtsLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addRole() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/role', this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Role added')
                    thisIns.addRoleModalActive = false
                    thisIns.addObject = {
                        name: '',
                        permissions: [],
                        areas: [],
                        territories: [],
                        districts: []
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.reloadRoles()
                })
            },
            saveRole() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/role/${  this.editObject.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.$printSuccess('Role saved')
                    thisIns.editRoleModalActive = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.reloadRoles()
                })
            },
            async removeRole(id) {

                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this role?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/role/${  id}`)
                    this.$printSuccess('Role removed')
                    
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                } finally {
                    this.reloadRoles()
                }
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }
        },
        computed: {
            roles() {
                return this.rolesRaw.map((role, index) => {
                    return {
                        index,
                        id: role.id,
                        name: role.name,
                        permissions: role.permissions,
                        permission_num: role.permissions.length,
                        areas: role.areas,
                        area_num: role.areas.length,
                        territories: role.territories,
                        territory_num: role.territories.length,
                        districts: role.districts,
                        district_num: role.districts.length
                    }
                })
            }
        },
        mounted() {
            this.selection.collection_name = 'roles'
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>